import $ from "jquery";
import {removeLocal} from "../outils/OutilsDivers";


$(document).ready(function () {


    let keysToRemove=['liens_index','tickets_index','jingles_index','versions_index','boutons_index','photos','citations','auteurs']
    keysToRemove.forEach(function (item){
        removeLocal(item+'_barre_scroll')

    })

    keysToRemove=['versions_index','citations','photos','auteurs']
    keysToRemove.forEach(function (item){
        removeLocal(item+'_versionOpen')
        removeLocal(item+'_filtreActif')
        removeLocal(item+'_itemActif')

    })
    removeLocal("citations_slide_active");


    removeLocal("barre_aux_item");
    removeLocal("barre_item");
    removeLocal("barre_scroll");
    removeLocal("barre_aux_scroll");

    removeLocal("tagTypeActif");
    removeLocal("parametreTypeActif");
    removeLocal("rayonOuvert");
    removeLocal("enedis_page");
    removeLocal("engie_page");


})







